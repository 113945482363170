/* eslint-disable max-len */
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
  DialogTitle,
  DialogSubTitle,
  MainDialogButton,
  SecondaryDialogButton,
  ButtonContainer,
} from '../../pages/VerifyCodeOtp/styles';

const theme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '25px',
        },
      },
    },
  },
});

function AlertDialog({
  selectedVehicleData,
  openAlert,
  handleCloseAlert,
  handleAceptAlert,
  handleCancelAlert,
  title,
  subtitle,
  mainbuttonTitle,
  secondarybuttonTitle,
}) {
  const isVehicleSelected = selectedVehicleData !== null;
  return (
    <div>
      {isVehicleSelected ? (
        <ThemeProvider theme={theme}>
          <Dialog
            open={openAlert}
            onClose={handleCloseAlert}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {title}
            </DialogTitle>
            <DialogContent>
              <DialogSubTitle id="alert-dialog-description">
                {subtitle}
              </DialogSubTitle>
            </DialogContent>
            <ButtonContainer>
              {secondarybuttonTitle ? (
                <SecondaryDialogButton
                  value={secondarybuttonTitle}
                  onClick={() => handleCancelAlert(secondarybuttonTitle)}
                >
                  {secondarybuttonTitle}
                </SecondaryDialogButton>
              ) : null}
              <MainDialogButton
                value={mainbuttonTitle}
                width={secondarybuttonTitle ? '50%' : '100%'}
                onClick={() => handleAceptAlert(mainbuttonTitle)}
                autoFocus
              >
                {mainbuttonTitle}
              </MainDialogButton>
            </ButtonContainer>
          </Dialog>
        </ThemeProvider>
      ) : null}
    </div>
  );
}
export { AlertDialog };
