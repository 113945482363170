import { useState } from 'react';

// Custom Hooks
// recibe dos parametros key  y el valor inicial
// Este hooks permite guardar en el LocalStorage los elementos
function useSesionStorage(key, initialValue) {
  // Metodo de Recuperación
  const [storedValue, setValue] = useState(() => {
    try {
      const item = sessionStorage.getItem(key);
      // El local storage guarda un string, por eso utilizamos JSON.parse(item) para recuperar er
      return item != null ? JSON.parse(item) : initialValue;
    } catch (e) {
      return initialValue;
    }
  });

  // Metodo de escritura
  const setLocalStorage = (value) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
      setValue(value);
    } catch (e) {
      console.log(e);
    }
  };

  return { storedValue, setLocalStorage };
  // devolvemos dos valores, 1 el valor que teniamos guardado, 2 la forma de actualizar el 
}

export { useSesionStorage };
