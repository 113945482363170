import { useNavigate } from 'react-router-dom'
import { loaderService } from '../services'
import { useApiService } from './useApiService'

const { authInstance } = useApiService()

const useAuthService = () => {
  const navigate = useNavigate()
  const loginService = async ({ captcha, valor, medio }) => {
    loaderService.show()
    try {
      const response = await authInstance.post('/v1/generar_otp', {
        valor,
        captcha,
        medio,
      })
      if (response.status === 200) {
        loaderService.hide()
        return response.data
      }
      loaderService.hide()
      return false
    } catch (error) {
      loaderService.hide()
      console.error('else Login', error)
    }
  }

  const loginServiceEmergency = async ({ captcha, token }) => {
    // loaderService.show()
    console.log(captcha,token)
    try {
      const response = await authInstance.post('/v1/apagado_qr_login', {
        captcha: captcha,
        token: token,
      })
      if (response.status === 200) {
        loaderService.hide()
        return response
      }
      loaderService.hide()
      return false
    } catch (error) {
      loaderService.hide()
      console.error('else Login', error)
    }
  }

  

  const verifyCodeService = async (params) => {
    const response = await authInstance.post('/v1/generar_token', params)
    try {
      if (response.status === 200 && response.data.token) {
        return response.data
      }
      return false
    } catch (error) {
      console.error('else', error)
    }
  }

  const verifyCodeRegister = async (params) => {
    loaderService.show()
    const response = await authInstance.post('/v1/validar/validar_otp', params)
    try {
      if (response.status === 200 && response.data.success === 1) {
        loaderService.hide()
        return response.data
      }
      loaderService.hide()
      return false
    } catch (error) {
      loaderService.hide()
      console.error('else', error)
    }
  }
  const logout = async () => {
    loaderService.show()
    try {
      const token = sessionStorage.getItem('token')
      const response = await authInstance.delete('/v1/logout', {
        headers: {
          Authorization: token,
        },
      })
      if (response.status === 200) {
        loaderService.hide()
        sessionStorage.clear()
        navigate('../')
        window.location.reload()
      }
      return response
    } catch (error) {
      loaderService.hide()
      console.error('else Logout', error)
    }
  }

  return {
    loginService,
    verifyCodeService,
    verifyCodeRegister,
    logout,
    loginServiceEmergency,
  }
}

export { useAuthService }
