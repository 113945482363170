import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadScriptByURL } from '../../utils';
import { SharedMap } from '../../pages';
import { useVehiclesService, useWebSockets } from '../../hooks';


const recaptchaKey = '6LcgYzUeAAAAAMzXJ7ddC57PNtA0k-stcLituAZE';
const initialRegion = {
  latitude: 4.3641759,
  longitude: -73.3833968,
  latitudeDelta: 1,
  longitudeDelta: 1,
};

function SharedMapContainer() {
  const { generateHeaderSocket, getVehicleInformation } = useVehiclesService();
  const { useSocket, position } = useWebSockets();
  const [vehicle, setVehicle] = useState({});
  const [authorization, setAuthorization] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [view] = useState({
    detail: false,
    clinic: null,
    center: {
      lat: initialRegion.latitude,
      lng: initialRegion.longitude,
    },
    zoomLevel: 6.5,
  });
  const navigate = useNavigate();
  const refSearch = window.location.search.slice(1);

  const vehicleInformation = async (token) => {
    const { vehiculo } = await getVehicleInformation(token);
    if (vehiculo) {
      setVehicle(vehiculo);
    }
  };

  const handleSharePosition = async (tokenCaptcha) => {
    const params = {
      captcha: tokenCaptcha,
      url: refSearch,
    };
    const header = await generateHeaderSocket(params);
    if (header) {
      setAuthorization(header);
      vehicleInformation(header);
    } else {
      setOpenAlert(true);
      setTimeout(() => {
        navigate('../');
      }, 10000);
    }
  };

  const generateCaptcha = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(recaptchaKey, { action: 'submit' })
        .then((token) => {
          handleSharePosition(token);
        });
    });
  };
  useEffect(() => {
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`,
      () => {},
    );
    setTimeout(() => {
      generateCaptcha();
    }, 1000);
  }, []);
  
  useEffect(() => {
    const values = Object.values(vehicle);
    if (values.length > 0) {
      useSocket(vehicle, authorization);
    }
  }, [vehicle]);

  const onCloseAlert = () => {
    setOpenAlert(false);
    navigate('../');
  };
  
  const selectedVehicleData = Object.values(position);



  return (
    <SharedMap
      view={view}
      vehicle={vehicle}
      position={position}
      openAlert={openAlert}
      authorization={authorization}
      onCloseAlert={onCloseAlert}
      selectedVehicleData={selectedVehicleData}
    />
  );
}

export default SharedMapContainer;
