import { styled, createTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Colors } from '../../theme';

export const HeaderCard = styled('div')(() => ({
  position: 'absolute',
  marginInline: 40,
  width: '50%',
  height: 80,
  borderEndEndRadius: 25,
  borderEndStartRadius: 25,
  left: 0,
  right: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
  background:
    'linear-gradient(90deg, rgba(39,38,76,1) 0%, rgba(46,49,142,1) 100%)',
  zIndex: 1,
  '@media (max-width: 600px)': {
    width: '100%',
    left: 0,
    right: 0,
  },
  '@media (max-width: 800px)': {
    width: '80%',
    left: 0,
    right: 0,
  },
}));

export const Title = styled(Typography)(() => ({
  color: Colors.WHITE,
  fontSize: '2.5rem',
  fontWeight: 'bold',
  margin: '0.5rem',
}));

export const TextParragraph = styled(Typography)(() => ({
  color: Colors.WHITE,
  fontSize: '1rem',
  fontWeight: 'bold',
  margin: '0.7rem',
}));

export const FooterContainer = styled('div')(() => ({
  background:
    'linear-gradient(90deg, rgba(39,38,76,1) 0%, rgba(46,49,142,1) 100%)',
  borderTopLeftRadius: 25,
  borderTopRightRadius: 25,
  left: 0,
  right: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: '1rem',
  color: Colors.WHITE,
  margin: '0 auto',
  height: '3.5rem',
  bottom: 0,
  position: 'absolute',
}));

export const MapSelectorContainer = styled('div')(() => ({
  position: 'absolute',
  top: 70,
  left: 0,
  right: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
  height: 70,
  width: '50%',
  zIndex: 1,
}));

export const themeSpeedDial = createTheme({
  components: {
    MuiSpeedDial: {
      styleOverrides: {
        fab: {
          color: Colors.BG_START,
          backgroundColor: Colors.GREEN_BACKGROUND,
          '&:hover': {
            backgroundColor: Colors.BLUE_BACKGROUND,
          },
        },
      },
    },
    MuiSpeedDialAction: {
      styleOverrides: {
        tooltipPlacementLeft: {
          whiteSpace: 'nowrap',
        },
        staticTooltipLabel: {
          borderRadius: '20px',
          backgroundColor: Colors.GRAY_BACKGROUND_MODAL,
          color: Colors.WHITE,
          fontWeight: '500',
        },
        fab: {
          backgroundColor: Colors.GRAY_BACKGROUND_MODAL,
          color: Colors.GREEN_BACKGROUND,
          '&:hover': {
            backgroundColor: Colors.GRAY_BACKGROUND_MODAL,
          },
        },
      },
    },
  },
});
