import { loader } from '../assets/images/splash';
import { loaderService } from '../services';
import { useApiService } from './useApiService';

const { vehíclesInstance } = useApiService();

const useVehiclesService = () => {
  const vehiclesList = async () => {
    const response = await vehíclesInstance.get('/v1/vehiculos');
    try {
      if (response.status === 200) {
        loaderService.hide();
        const { vehiculos } = response.data;
        return vehiculos;
      }
      loaderService.hide();
      return false;
    } catch (error) {
      console.error('else', error);
      loaderService.hide();
    }
  };

  const getLineByChasis = async (chasis) => {
    const response = await vehíclesInstance.get(`/v1/serial?chasis=${chasis}`);
    try {
      // loaderService.show();
      if (response.status === 200) {
        loaderService.hide();
        return response.data;
      }
      return false;
    } catch (error) {
      console.error('else', error);
    } finally {
      // loaderService.hide();
    }
  };

  const turnOffVehicle = async (params) => {
    loaderService.show();
    const response = await vehíclesInstance.post('/v1/encendido/off/', {
      chasis: params,
    });
    try {
      if (response.status === 200) {
        return response.data;
      }
      return false;
    } catch (error) {
      console.error('else', error);
    } finally {
      loaderService.hide();
    }
  };

  const turnONVehicle = async (params) => {
    loaderService.show();
    const response = await vehíclesInstance.post('/v1/encendido/on/', {
      chasis: params,
    });
    try {
      if (response.status === 200) {
        loaderService.hide();
        return response.data;
      }
      return false;
    } catch (error) {
      loaderService.hide();
      console.error('else', error);
    }
  };

  const getUrlService = async (tokenCaptcha, selectedVehicle) => {
    const response = await vehíclesInstance.post('/v1/compartir/url', {
      captcha: tokenCaptcha,
      chasis: selectedVehicle.chasis,
      minutos: 240,
    });
    try {
      if (response.status === 200) {
        return response.data;
      }
      return false;
    } catch (error) {
      console.error('else', error);
    }
  };

  const generateHeaderSocket = async (params) => {
    loaderService.show();
    const response = await vehíclesInstance.post('/v1/compartir/token', params);
    try {
      if (response.status === 200) {
        loaderService.hide();
        const { header } = response.data;
        return header;
      }
      loaderService.hide();
      return false;
    } catch (error) {
      loaderService.hide();
      console.error('else', error);
    }
  }; 

  const getVehicleInformation = async (header) => {
    const response = await vehíclesInstance.get(
      `/v1/compartir/vehiculo?header=${header}`,
    );
    try {
      if (response.status === 200) {
        return response.data;
      }
      return false;
    } catch (error) {
      console.error('else', error);
    }
  }; 

  const checkStatusRelay = async (param) => {
    const { status, data } = await vehíclesInstance.get(
      `/v1/relay?chasis=${param}`,
    );
    try {
      if (status === 200) {
        return data;
      }
      return false;
    } catch (error) {
      console.error('else', error);
    }
  };

  const writeMessageWS = async (chasis, km, plataforma) => {
    try {
      const response = await vehíclesInstance.post('/v1/mensaje/dispositivo/', {
        chasis,
        mensaje: km,
        operacion: plataforma,
      });
      if (response.status === 200) {
        loaderService.hide();
        return response.data;
      }
      return false;
    } catch (error) {
      // await logError(errorMsgs.ERROR_LOGGER_UPDATE_KM_VEHICLE, false)
      console.error('else', error);
    }
  };
  
  return {
    generateHeaderSocket,
    getVehicleInformation,
    getLineByChasis,
    turnOffVehicle,
    getUrlService,
    turnONVehicle,
    vehiclesList,
    checkStatusRelay,
    writeMessageWS,
  };
};

export { useVehiclesService };
