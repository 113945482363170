import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Card } from '@mui/material';
import { Colors } from '../../theme';

export const ContainerStyle = styled(Container)(() => ({
  overflow: 'hidden',
  minHeight: '100vh',
  background: Colors.GRAY_BACKGROUND,
  minWidth: '100vw',
}));

export const HeaderCard = styled(Card)(() => ({
  flex: 1,
  background:
    'linear-gradient(90deg, rgba(39,38,76,1) 0%, rgba(46,49,142,1) 100%)',
  margin: 15,
  height: 150,
  marginInlineStart: 'auto',
  borderRadius: 25,

}));

export const Title = styled(Typography)(() => ({
  color: Colors.TEXT_CIAN,
  fontSize: '1.5rem',
  fontWeight: 'bold',
  margin: '0.5rem',
}));

export const SubTitle = styled('div')(() => ({
  color: Colors.GRAY_TEXT,
  fontSize: '1.1rem',
  fontWeight: 'normal',
  margin: '0.5rem',
}));

export const TextBold = styled(Typography)(() => ({
  color: Colors.TEXT_CIAN,
  fontSize: '1.5rem',
  fontWeight: 'bold',
  margin: '0.5rem',
}));

export const TextTime = styled(Typography)(() => ({
  color: Colors.PURPLE,
  fontSize: '1.1rem',
  fontWeight: 'bold',
  margin: '0.5rem',
}));

export const OtpInput = styled('input')(() => ({
  background: Colors.GRAY_INDICATOR,
  borderRadius: 50,
  border: '',
  borderWidth: 4,
  borderColor: Colors.PURPLE,
  color: Colors.WHITE,
  fontSize: '1rem',
  fontWeight: 'bold',
  padding: '0.5rem',
  width: 50,
  height: 50,
}));

export const TextParragraph = styled(Typography)(() => ({
  color: Colors.BG_START,
  fontSize: '1rem',
  fontWeight: 'bold',
  margin: '0.5rem',
}));

export const TextParragraphError = styled(Typography)(() => ({
  color: Colors.RED,
  fontSize: '1rem',
  fontWeight: 'bold',
  margin: '0.5rem',
}));

export const MidContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  width: '100%',
  height: '100%',
  marginTop: '10rem',
}));

export const FooterContainer = styled('div')(() => ({
  background: Colors.WHITE,
  borderTopLeftRadius: 25,
  borderTopRightRadius: 25,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  height: '10rem',
  bottom: 0,
  left: 0,
  right: 0,
  position: 'absolute',
}));

export const ImageBackground = styled('img')(() => ({
  width: 600,
  height: '100%',
  objectFit: 'cover',
}));

export const ContainerImg = styled('div')(() => ({
  margin: '0 auto',
  marginBottom: '10rem',
  position: 'absolute',
  right: 0,
  bottom: 10,
}));

export const ButtonApp = styled(Button)(() => ({
  background: Colors.PURPLE,
  overflow: 'hidden',
  position: 'relative',
  height: '100%',
}));

export const ButtonResend = styled(Button)(() => ({
  backgroundColor: Colors.TRANSPARENT,
  border: `1px solid ${Colors.BG_END}`,
  color: Colors.BG_START,
  overflow: 'hidden',
  position: 'relative',
  height: '60px',
  width: '35%',
  borderRadius: '30px',
  fontWeight: 'bold',
  fontSize: '18px',
  '&:hover': {
    backgroundColor: Colors.GREEN_BACKGROUND,
    border: 'none',
  },
}));

export const DialogTitle = styled(Typography)(() => ({
  color: Colors.PURPLE_MEDIUM,
  fontSize: '1.5rem',
  fontWeight: 'bold',
  margin: '0.5rem',
  position: 'relative',
  top: '20px',
  paddingLeft: '1rem',
  paddingRight: '1rem',
}));

export const DialogSubTitle = styled(Typography)(() => ({
  color: Colors.PURPLE_LIGHT,
  fontSize: '1.1rem',
  fontWeight: 'normal',
  margin: '0.5rem',
  position: 'relative',
  bottom: '5px',
}));
export const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '50px',
}));

export const MainDialogButton = styled(Button)((props) => ({
  background: Colors.GREEN_BACKGROUND,
  width: props.width,
  color: Colors.BG_START,
  margin: '0',
  borderRadius: 'unset',
  fontWeight: 'bold',
  '&:hover': {
    background: Colors.GREEN_BACKGROUND,
  },
}));

export const SecondaryDialogButton = styled(Button)(() => ({
  width: '50%',
  borderRadius: 'unset',
  background: Colors.WHITE,
  color: Colors.GRAY_BACKGROUND_MODAL,
  fontWeight: 'bold',
  '&:hover': {
    background: Colors.WHITE,
  },
}));
