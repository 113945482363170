import React, { createContext, useMemo, useState } from 'react';

export const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [token] = useState(sessionStorage.getItem('token'));
  const [authUser, setAuthUser] = useState({ authLogin: false, authToken: Boolean(token) });

  const login = (uuid) => {
    if (uuid) {
      setAuthUser({ ...authUser, authLogin: true });
    }
  };

  const verify = (tokens) => {
    if (tokens) {
      setAuthUser({ ...authUser, authToken: true });
    }
  };
  const memo = useMemo(() => ({ login, verify, authUser }), [authUser]);
  return (
    <AuthContext.Provider value={memo}>
      {children}
    </AuthContext.Provider>
  );
}
