import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { loaderService } from '../../services/core';

function Loader() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    loaderService.onChange((value) => setOpen(value));
  }, []);

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
export { Loader };
