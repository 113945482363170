/* eslint-disable no-useless-escape */
const isPhoneValid = (phone) => {
  const regEx = /\d{10,20}/;
  return regEx.test(phone);
};

const isEmailValid = (email) => {
  const regEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  return regEx.test(email);
};

export const inputValidations = {
  isPhoneValid,
  isEmailValid,
};
