import { useState } from 'react';
import { decode } from '@googlemaps/polyline-codec';
import { useNavigate } from 'react-router-dom';
import { shared } from '../assets/images';

const { REACT_APP_SOCKET_URL } = process.env;

const useWebSockets = () => {
  const [position, setPosition] = useState({});
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  function connecting(vehicle, tokenHeader) {
    let recconnect = true;
    let ws = '';
    if (tokenHeader) {
      ws = new WebSocket(
        `${REACT_APP_SOCKET_URL}/compartir?token=${tokenHeader}`,
      );
    } else {
      ws = new WebSocket(
        `${REACT_APP_SOCKET_URL}?authorization=${
          JSON.parse(token)
        }&chasis=${vehicle.chasis}`,
      );
    }

    ws.onopen = () => {
      console.log('open', (vehicle.chasis || vehicle.Chasis));
    };

    // websocket onclose event listener
    ws.onclose = () => {
      if (recconnect) {
        ws = null;
        setTimeout(() => {
          connecting(vehicle, tokenHeader);
        }, 1000);
      }
    };

    // websocket onerror event listener
    ws.onerror = () => {
      recconnect = false;
      navigate('../');
    };

    ws.onmessage = (event) => {
      if (event.data === 'bye') {
        recconnect = false;
        return;
      }
      const partes = event.data.split('¬');
      if (partes[0] !== 'gps') {
        return;
      }
      const latitude = partes[3];
      if (typeof latitude === 'string') {
        const onChangeImage = () => {
          if (partes[7] === '1') {
            return partes[6] === '1'
              ? shared.motorON
              : shared.motorOFF;
          }
          if (partes[7] === '2') {
            return shared.hhver;
          }
          return shared.motorNO;
        };
        const positionVehicle = decode(latitude, 5);
        try {
          setPosition((prev) => ({
            ...prev,
            [(vehicle.Chasis || vehicle.chasis)]: {
              img: onChangeImage(),
              velocity: partes[4],
              turnOn: partes[6],
              latitude: positionVehicle[0][0],
              longitude: positionVehicle[0][1],
              meters: partes[5],
              onLine: partes[7],
              ...vehicle,
              chasis: (vehicle.Chasis || vehicle.chasis),
              angle: partes[2],
              time: +partes[1],
            },
          }));
        } catch (err) {
          console.log('error', err);
        }
      }
    };
  }
  const useSocket = (vehicles, tokenHeader) => {
    if (!tokenHeader) {
      vehicles.map((vehicle) => connecting(vehicle));
    } else {
      connecting(vehicles, tokenHeader);
    }
  };

  return { useSocket, position };
};

export { useWebSockets };
