import React, { useEffect, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { inputValidations, loadScriptByURL } from '../../utils'
import { loaderService } from '../../services'
import { useAuthService, useSesionStorage, AuthContext } from '../../hooks'

const recaptchaKey = '6LcgYzUeAAAAAMzXJ7ddC57PNtA0k-stcLituAZE'

function EmergencyMapContainer() {
  const { loginServiceEmergency } = useAuthService()
  const { login } = useContext(AuthContext)
  const [token, setToken] = useState('')
  const [tokenCaptcha, setTokenCaptcha] = useState('')
  const { verify } = useContext(AuthContext)

  const { setLocalStorage } = useSesionStorage('token')
  const navigate = useNavigate()

  useEffect(() => {
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`,
      () => {}
    )
    const urlParams = new URLSearchParams(window.location.search)
    const tParam = urlParams.get('t')
    setToken(tParam)
    setTimeout(() => {
      handleSubmit()
    }, 3000)
  }, [])

  useEffect(async () => {
    if (tokenCaptcha !== '' && token !== '') {
      const data = await onSubmit()
    }
  }, [tokenCaptcha])

  const onSubmit = async (params) => {
    loaderService.show()
    sessionStorage.clear()
    const response = await loginServiceEmergency({
      captcha: tokenCaptcha,
      token: token,
    })

    if (response.data.token) {
      verify(token)
      setLocalStorage(response.data.token)
      navigate('../mapEmergency', {
        replace: true,
        state: {
          valor: response.data.token,
        },
      })
    } else if (response.status === 403) {
      alert('Error de autenticación, por favor intenta de nuevo más tarde')
      navigate('../')
    } else {
      alert('Tu QR expiró, por favor genera uno nuevo desde la app Trakku')
      navigate('../')
    }
    loaderService.hide()
  }

  const handleSubmit = (event) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(recaptchaKey, { action: 'submit' })
        .then((token) => {
          setTokenCaptcha(token)
        })
    })
  }

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        backgroundColor: 'black',
        justifyContent: 'center',
        alignContent: 'center',
      }}
    >
      <img
        style={{
          width: '50vw',
          maxWidth: '300px',
        }}
        src="https://trakku.com.co/wp-content/uploads/2023/07/logo-trakku.svg"
      />
    </div>
  )
}

export default EmergencyMapContainer
