import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Login } from '../../pages';
import { inputValidations, loadScriptByURL } from '../../utils';
import { useAuthService, AuthContext } from '../../hooks';
import { loaderService } from '../../services';

const recaptchaKey = '6LcgYzUeAAAAAMzXJ7ddC57PNtA0k-stcLituAZE';

function LoginContainer() {
  const [inputType, setInputType] = useState('telefono');
  const [userInput, setUserInput] = useState('');
  const { loginService } = useAuthService();
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();


  useEffect(() => {
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`,
      () => {},
    );
  }, []);

  const onSubmit = async (params) => {
    loaderService.show();
    sessionStorage.clear();
    const { uuid } = await loginService({
      captcha: params.captcha,
      valor: params[inputType],
      medio: inputType,
    });

    if (uuid !== null) {
      login(uuid);
      navigate('../verify', {
        replace: true,
        state: {
          uuid,
          captcha: params.captcha,
          valor: params[inputType],
          medio: inputType,
        },
      });
    } else {
      console.log('error');
    }
    loaderService.hide();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(recaptchaKey, { action: 'submit' })
        .then((token) => {
          onSubmit({ ...userInput, captcha: token });
        });
    });
  };

  const onChangeInput = (event) => {
    const input = event.target.value;
    const type = inputValidations.isEmailValid(input);
    const isType = type ? 'correo' : 'telefono';
    setInputType(isType);
    setUserInput({ [isType]: isType === 'telefono' ? `+57${input}` : input });
  };

  return <Login handleSubmit={handleSubmit} onChangeInput={onChangeInput} />;
}

export default LoginContainer;
