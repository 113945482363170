import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadScriptByURL } from '../../utils';
import { VerifyCodeOtp } from '../../pages';
import { useAuthService, useSesionStorage, AuthContext } from '../../hooks';

const DEFAULT_TIME = 60;
const recaptchaKey = '6LcgYzUeAAAAAMzXJ7ddC57PNtA0k-stcLituAZE';

function VerifyCodeOtpContainer() {
  const [code, setCode] = useState('');
  const [errorMsgs, setErrorMsgs] = useState(null);
  const [userInformation, setUserInfo] = useState({});
  const [time, setTime] = useState(DEFAULT_TIME);
  const [openDialog, setOpenDialog] = useState(false);
  const { verifyCodeService, loginService } = useAuthService();
  const { setLocalStorage } = useSesionStorage('token');
  const { verify } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { state } = location;
    setUserInfo(state);
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`,
      () => {},
    );
  }, [location]);

  const generateToken = async ({ captcha, medio }) => {
    userInformation.uuid = await loginService({
      captcha,
      valor: userInformation.valor,
      medio: medio === 'SMS' ? 'telefono' : medio,
    });
  };

  const handleSubmit = (event) => {
    if (userInformation.medio === 'correo') {
      event.preventDefault();
      setTime(DEFAULT_TIME);
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(recaptchaKey, { action: 'submit' })
          .then((token) => {
            generateToken({ captcha: token, medio: 'correo' });
          });
      });
    } else {
      setOpenDialog(true);
    }
  };

  const handleSubmitDialog = (event) => {
    setTime(DEFAULT_TIME);
    setOpenDialog(false);
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(recaptchaKey, { action: 'submit' })
        .then((token) => {
          generateToken({ captcha: token, medio: event });
        });
    });
  };
  const handleCloseDialog = () => setOpenDialog(false);

  const verifyCode = async (event, tokenCaptcha) => {
    const uuid = userInformation.uuid.uuid
      ? userInformation.uuid.uuid
      : userInformation.uuid;
    const { token } = await verifyCodeService({
      captcha: tokenCaptcha,
      otp: event.toString(),
      uuid,
      plataforma_id: 2,
    });
    if (token) {
      verify(token);
      setLocalStorage(token);
      navigate('../map', {
        replace: true,
        state: {
          valor: token,
        },
      });
    } else {
      setCode('');
      setErrorMsgs('El código ingresado es incorrecto');
    }
  };

  const handleChange = (event) => {
    setCode(event);
    setErrorMsgs(null);
    if (event.length === 4) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(recaptchaKey, { action: 'submit' })
          .then((token) => {
            verifyCode(event, token);
          });
      });
    }
  };

  const remainTime = () => {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    let totalSecs = seconds;
    if (seconds < 10) {
      totalSecs = `0${seconds}`;
    }
    return `${minutes}:${totalSecs}`;
  };

  useEffect(() => {
    const playingTimer = setTimeout(() => {
      if (time > 0) {
        setTime(time - 1);
      }
    }, 1000);
    return () => {
      clearTimeout(playingTimer);
    };
  }, [time]);

  return (
    <VerifyCodeOtp
      code={code}
      errorMsgs={errorMsgs}
      remainTime={remainTime}
      openDialog={openDialog}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      userInformation={userInformation}
      handleCloseDialog={handleCloseDialog}
      handleSubmitDialog={handleSubmitDialog}
    />
  );
}

export default VerifyCodeOtpContainer;
