/* eslint-disable no-param-reassign */
import axios from 'axios';
import { loaderService } from '../services';

let auth = null;

const useApiService = () => {
  const { REACT_APP_BASE_AUTH_URL, REACT_APP_BASE_VEHICLES_URL } = process.env;

  const authInstance = axios.create({
    baseURL: REACT_APP_BASE_AUTH_URL,
    withCredentials: false,
    timeout: 30000,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });

  const vehíclesInstance = axios.create({
    baseURL: REACT_APP_BASE_VEHICLES_URL,
    withCredentials: false,
    timeout: 30000,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: auth,
    },
  });

  authInstance.interceptors.response.use(
    async (response) => {
      if (response.data.token) {
        auth = response.data.token;
        // await storageService.setToken(
        //   response.data.token,
        // );
        vehíclesInstance.defaults.headers.common.Authorization = auth;
      }
      return response;
    },
    (error) => {
      loaderService.hide();
      if (error.response.status === 403) {
        // storageService.clear();
      }
      return { ...error.response.data, status: error.response.status };
    },
  );

  vehíclesInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log('errorVehicleI', error);
      if (error.response.status === 403) {
        // storageService.clear();
        // loaderService.hide();
      }
      return error.response.status;
    },
  );

  vehíclesInstance.interceptors.request.use(async (config) => {
    const token = sessionStorage.getItem('token');
    config.headers.Authorization = auth || JSON.parse(token);
    return config;
  }, (error) => {
    loaderService.hide();
    console.log('errorVehicleInter', error);
  });

  return { authInstance, vehíclesInstance };
};

export { useApiService };
