import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { Colors } from '../../theme';

export const ContainerStyle = styled('section')(() => ({
  background: Colors.WHITE,
  borderRadius: 10,
  marginTop: '1.5rem',
  left: 0,
  right: 0,
  boxShadow: '6px 7px 7px 3px rgba(0,0,0,0.19)',
}));

function MapSelector({ vehicles, handleChange, selectedVehicle }) {
  return (
    <ContainerStyle>
      <FormControl fullWidth>
        <Select
          value={selectedVehicle}
          label="Vehículo"
          onChange={handleChange}
          variant="filled"
        >
          {vehicles.map((vehicle) => (
            <MenuItem
              key={vehicle.chasis}
              value={vehicle.label}
              selected={vehicle.value === selectedVehicle}
            >
              {vehicle.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ContainerStyle>
  );
}

export { MapSelector };
