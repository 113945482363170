import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import { shared } from '../../assets/images';
import {
  ContainerStyle, InputText, ButtonApp, theme, Title, SubTitle, TitlesContainer,
} from './styles';
import { Colors } from '../../theme';

function Copyright(props) {
  return (
    <Typography variant="body2" color={Colors.WHITE} align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.autecomobility.com/">
        Trakku by Auteco Mobility
      </Link>
      {' '}
      {new Date().getFullYear()}
      .
    </Typography>
  );
}

function Login({ onChangeInput, handleSubmit }) {
  return (
    <ContainerStyle component="main">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          src={shared.trakku}
          alt="Trakku"
          style={{ heigth: '300px', width: '300px' }}
        />
        <TitlesContainer>
          <Title>
            Bienvenido
          </Title>
          <SubTitle>
            Ingresa tu email o número de celular.
          </SubTitle>
        </TitlesContainer>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <ThemeProvider theme={theme}>
            <InputText
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              onChange={onChangeInput}
              autoComplete="email"
              InputLabelProps={{
                shrink: true,
                color: Colors.WHITE,
              }}
              variant="standard"
            />

          </ThemeProvider>
          <ButtonApp
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            ENTRAR
          </ButtonApp>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </ContainerStyle>
  );
}

export default Login;
