import React, { useEffect } from 'react'
import { Wrapper } from '@googlemaps/react-wrapper'
import { MapGoogle, MapSelector, MenuMap } from '../../components'
import {
  MapSelectorContainer,
  FooterContainer,
  TextParragraph,
  HeaderCard,
  Title,
} from './styles'

function MapPage({
  view,
  setView,
  vehicles,
  position,
  urlShare,
  userLogout,
  handleChange,
  sharePosition,
  selectedVehicle,
  selectedVehicleData,
}) {
  const [actualLat, setActualLat] = React.useState(0)
  const [actualLng, setActualLng] = React.useState(0)

  const center = async () => {
    if (selectedVehicleData.length > 0) {
      const latitudeBySocket = selectedVehicleData[0].latitude
      const longitudeBySocket = selectedVehicleData[0].longitude
      // console.log(
      //   'latitudeBySocket',
      //   latitudeBySocket !== actualLat || longitudeBySocket !== actualLng
      // )
      if (latitudeBySocket !== actualLat || longitudeBySocket !== actualLng) {
        setView({
          detail: false,
          clinic: null,
          center: {
            lat: latitudeBySocket,
            lng: longitudeBySocket,
          },
          zoomLevel: 15,
        })
        setActualLat(latitudeBySocket)
        setActualLng(longitudeBySocket)
      }
    } else {
      setView({
        detail: false,
        clinic: null,
        center: {
          lat: 4.3641759,
          lng: -73.3833968,
        },
        zoomLevel: 6.5,
      })
    }
  }

  useEffect(() => {
    // console.log(selectedVehicleData)
    center()
  }, [selectedVehicleData, selectedVehicle])

  useEffect(() => {
    setActualLat(0)
    setActualLng(0)
  }, [selectedVehicle])

  return (
    <div
      style={{
        height: '100vh',
        minWidth: '100vw',
        display: 'flex',
        width: '100vw',
      }}
    >
      <HeaderCard>
        <Title>TRAKKU</Title>
      </HeaderCard>
      <MapSelectorContainer>
        <MapSelector
          vehicles={vehicles}
          handleChange={handleChange}
          selectedVehicle={selectedVehicle}
        />
      </MapSelectorContainer>
      <Wrapper
        apiKey="AIzaSyAINQfRs6ACIur8xjLP8pCIXrZFIoJh39o"
        version="weekly"
      >
        <MapGoogle
          view={view}
          vehicles={position}
          selectedVehicleData={selectedVehicleData}
        />
      </Wrapper>
      <MenuMap
        urlShare={urlShare}
        selectedVehicleData={selectedVehicleData}
        sharePosition={sharePosition}
        userLogout={userLogout}
      />
      <FooterContainer>
        <TextParragraph>{selectedVehicle}</TextParragraph>
      </FooterContainer>
    </div>
  )
}

export default MapPage
