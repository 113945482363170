import React from 'react';
import OtpInput from 'react-otp-input';
import { Colors } from '../../theme';

function OtpInputField({ handleChange, code }) {
  return (
    <OtpInput
      value={code}
      onChange={handleChange}
      numInputs={4}
      separator={<span style={{ width: '8px' }} />}
      isInputNum
      shouldAutoFocus
      inputStyle={{
        border: `2px solid ${Colors.GREEN_BACKGROUND}`,
        borderRadius: '50px',
        width: '60px',
        height: '60px',
        fontSize: '20px',
        color: '#000',
        fontWeight: '400',
        caretColor: 'blue',
      }}
      focusStyle={{
        border: `1px solid ${Colors.BACKGROUND_CIAN}`,
        outline: 'none',
      }}
    />
  );
}

export { OtpInputField };
