import React, { useState, useEffect } from 'react';
import { useWebSockets, useVehiclesService, useAuthService } from '../../hooks';
import { capitalize, loadScriptByURL } from '../../utils';
import { MapPage } from '../../pages';

const recaptchaKey = '6LcgYzUeAAAAAMzXJ7ddC57PNtA0k-stcLituAZE';
const initialRegion = {
  latitude: 4.3641759,
  longitude: -73.3833968,
  latitudeDelta: 1,
  longitudeDelta: 1,
};
function MapContainer() {
  const [urlShare, setUrlShare] = useState('');
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [selectedVehicleData, setSelectedVehicleData] = useState(null);
  const [view, setView] = useState({
    detail: false,
    clinic: null,
    center: {
      lat: initialRegion.latitude,
      lng: initialRegion.longitude,
    },
    zoomLevel: 6.5,
  });
  const { logout } = useAuthService();
  const {
    vehiclesList, turnOffVehicle, turnONVehicle, getUrlService, 
  } = useVehiclesService();
  const { useSocket, position } = useWebSockets();

  useEffect(() => {
    (async () => {
      const vehiculos = await vehiclesList();
      if (Array.isArray(vehiculos)) {
        const veh = vehiculos.map((vehicle) => ({
          ...vehicle,
          ...vehicle.vehiculo,
          label: `${capitalize(vehicle.vehiculo?.Marca || '')} ${
            vehicle.vehiculo?.Modelo || ''
          }`,
          value: vehicle.vehiculo.Chasis,
        }));
        if (veh.length > 0) {
          setVehicles(veh);
          useSocket(veh);
          setSelectedVehicle(veh[0].label);
        }
      }
    })();
  }, []);

  useEffect(() => {
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`,
      () => {},
    );
  }, []);

  useEffect(() => {
    const initialVehicleSelected = Object.values(position).filter(
      (item) => item.label === selectedVehicle,
    );
    setSelectedVehicleData(initialVehicleSelected);
  }, [position]);

  const handleChange = (event) => {
    const selected = event.target.value;
    const vehicleSelected = Object.values(position).filter(
      (item) => item.label === selected,
    );
    setSelectedVehicleData(vehicleSelected);
    setSelectedVehicle(selected);
  };

 

  const userLogout = async () => {
    await logout();
  };

  const sharePosition = async () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(recaptchaKey, { action: 'submit' })
        .then(async (token) => {
          const { url } = await getUrlService(token, selectedVehicleData[0]);
          if (url) {
            setUrlShare(url);
          }
        });
    });
  };

  return (
    <MapPage
      view={view}
      setView={setView}
      urlShare={urlShare}
      position={position}
      vehicles={vehicles}
      userLogout={userLogout}
      handleChange={handleChange}
      sharePosition={sharePosition}
      selectedVehicle={selectedVehicle}
      selectedVehicleData={selectedVehicleData}
    />
  );
}

export default MapContainer;
