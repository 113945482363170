import React from 'react';
import { CardContent } from '@mui/material';
import { OtpInputField } from '../../components';
import { shared } from '../../assets/images';

import {
  Title,
  SubTitle,
  TextTime,
  TextBold,
  HeaderCard,
  ContainerImg,
  MidContainer,
  ContainerStyle,
  TextParragraph,
  FooterContainer,
  ImageBackground,
  TextParragraphError,
  ButtonResend,
} from './styles';
import { AlertDialog } from '../../components/AlertDialog';

const title = '¿No has recibido el codigo?';
const subtitle = 'Intenta recibirlo por otro medio';
const mainbuttonTitle = 'SMS';
const secondarybuttonTitle = 'llamada';

function VerifyCodeOtp({
  handleCloseDialog,
  handleSubmitDialog,
  userInformation,
  handleSubmit,
  handleChange,
  remainTime,
  errorMsgs,
  code,
  openDialog,
}) {
  const time = remainTime();
  let timeOff = <ButtonResend onClick={handleSubmit} variant="contained">Reenviar</ButtonResend>;
  if (time !== '0:00') {
    timeOff = (
      <SubTitle variant="subtitle1" component="p">
        Puedes volver a intentarlo en
        &nbsp;
        <TextTime>{time}</TextTime>
        &nbsp;
        minutos
      </SubTitle>
    );
  }

  return (
    <ContainerStyle>
      <AlertDialog
        mainbuttonTitle={mainbuttonTitle}
        secondarybuttonTitle={secondarybuttonTitle}
        title={title}
        subtitle={subtitle}
        openAlert={openDialog}
        selectedVehicleData={{}}
        handleCloseAlert={handleCloseDialog}
        handleAceptAlert={handleSubmitDialog}
        handleCancelAlert={handleSubmitDialog}
      />
      <HeaderCard>
        <CardContent>
          <Title variant="h5" component="h2">
            ¿Eres tu?
          </Title>
          <SubTitle variant="subtitle1" component="p">
            Hemos enviado un código a:
          </SubTitle>
          <TextBold variant="subtitle1" component="p">
            {userInformation?.valor || ''}
          </TextBold>
        </CardContent>
      </HeaderCard>
      <MidContainer>
        <TextParragraph>
          Ingresa el código que hemos enviado a continuacion
        </TextParragraph>
        <OtpInputField handleChange={handleChange} code={code} />
        {errorMsgs ? (
          <TextParragraphError>{errorMsgs}</TextParragraphError>
        ) : null}
      </MidContainer>
      <ContainerImg>
        <ImageBackground src={shared.verifyBackground} />
      </ContainerImg>
      <FooterContainer>
        {timeOff}
      </FooterContainer>
    </ContainerStyle>
  );
}

export default VerifyCodeOtp;
