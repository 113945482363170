export default {
  PURPLE: '#8738D2',
  TEXT_CIAN: '#03CFE1',
  BACKGROUND_CIAN: '#242050',
  TEXT_CALENDAR: '#232052C7',
  GRAY_BACKGROUND_MODAL: '#91919E',
  SHADOW_PURPLE: '#504E74',
  PURPLE_LIGHT: '#2F2660',
  GRAY_LIGHT: '#F7F7F7',
  ERROR_TEXT: '#CB0056',
  BOTTON_ALERT: '#FFFFFFF0',
  GRAY_TEXT: '#9CA1AC',
  PURPLE_MEDIUM: '#441076',
  GRAY_BACKGROUND: '#EFEFEF',
  GRAY_SEPARATOR: '#DBDBDB',
  GRAY_TEXT_ALERT: '#00000036',
  GREEN_ON: '#90ff90',
  RED: '#FF0020',
  RED_INDICATOR: '#6e0000',
  BG_END: '#2E318E',
  BG_START: '#27264C',
  BLACK: '#000000',
  INPUT_BORDER: 'rgba(0,0,0,0.2)',
  SECONDARY_TEXT: '#606569',
  PRIMARY: '#0075DC',
  TRANSPARENT: 'transparent',
  WHITE: '#FFFFFF',
  BG_MODAL: 'rgba(0,0,0,0.8)',
  GRAY_INDICATOR: '#BCC0C4',
  GREEN_INDICATOR: '#85CB7E',
  GREEN_BACKGROUND: '#D7F200',
  BLUE_BACKGROUND: '#00F3FF',

};
