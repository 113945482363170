import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import React, { useState, useEffect } from 'react'
import { useWebSockets, useVehiclesService, useAuthService } from '../../hooks'
import { capitalize, loadScriptByURL } from '../../utils'
import { MapPageEmergency } from '../../pages'

const recaptchaKey = '6LcgYzUeAAAAAMzXJ7ddC57PNtA0k-stcLituAZE'
const initialRegion = {
  latitude: 4.3641759,
  longitude: -73.3833968,
  latitudeDelta: 1,
  longitudeDelta: 1,
}
function MapContainerEmergency() {
  const [urlShare, setUrlShare] = useState('')
  const [vehicles, setVehicles] = useState([])
  const [selectedVehicle, setSelectedVehicle] = useState('')
  const [selectedVehicleData, setSelectedVehicleData] = useState(null)
  const [showSelectFromMultiplesVehicles, setShowSelectFromMultiplesVehicles] =
    useState(false)
  const [view, setView] = useState({
    detail: false,
    clinic: null,
    center: {
      lat: initialRegion.latitude,
      lng: initialRegion.longitude,
    },
    zoomLevel: 6.5,
  })
  const { logout } = useAuthService()
  const { vehiclesList, turnOffVehicle, turnONVehicle, getUrlService } =
    useVehiclesService()
  const { useSocket, position } = useWebSockets()

  useEffect(() => {
    ;(async () => {
      const vehiculos = await vehiclesList()
      if (Array.isArray(vehiculos)) {
        const veh = vehiculos.map((vehicle) => ({
          ...vehicle,
          ...vehicle.vehiculo,
          label: `${capitalize(vehicle.vehiculo?.Marca || '')} ${
            vehicle.vehiculo?.Modelo || ''
          }`,
          value: vehicle.vehiculo.Chasis,
        }))

        if (veh.length > 0) {
          setVehicles(veh)
          useSocket(veh)
          setSelectedVehicle(veh[0].label)
        }

        if (veh.length > 1) {
          setShowSelectFromMultiplesVehicles(true)
        }
      }
    })()
  }, [])

  useEffect(() => {
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`,
      () => {}
    )
  }, [])

  useEffect(() => {
    const initialVehicleSelected = Object.values(position).filter(
      (item) => item.label === selectedVehicle
    )
    setSelectedVehicleData(initialVehicleSelected)
  }, [position])

  const handleChange = (event) => {
    const selected = event.target.value
    const vehicleSelected = Object.values(position).filter(
      (item) => item.label === selected
    )
    setSelectedVehicleData(vehicleSelected)
    setSelectedVehicle(selected)
    setShowSelectFromMultiplesVehicles(false)
  }

  const userLogout = async () => {
    await logout()
  }

  const sharePosition = async () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(recaptchaKey, { action: 'submit' })
        .then(async (token) => {
          const { url } = await getUrlService(token, selectedVehicleData[0])
          if (url) {
            setUrlShare(url)
          }
        })
    })
  }

  return (
    <>
      {showSelectFromMultiplesVehicles ? (
        <>
          <div>
            <div
              style={{
                padding: '20px 0',
                height: '100vh',
                background:
                  'linear-gradient(90deg, rgba(39,38,76,1) 0%, rgba(46,49,142,1) 100%)',
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
               
              }}
            >
              <img
                src={
                  'https://trakku-admin.web.app/static/media/trakku.4cedb86c3a1cd13dcef9.png'
                }
                alt="Trakku"
                style={{ heigth: '300px', width: '300px' }}
              />
              <p>Por favor selecciona la moto a la que deseas acceder</p>

              <Select
                value={''}
                label="Vehículo"
                onChange={handleChange}
                variant="filled"
                style={{
                  minWidth: '150px',
                  background: '#ffffff63',
                  marginTop: '20px',
                }}
              >
                {vehicles.map((vehicle) => (
                  <MenuItem
                    key={vehicle.chasis}
                    value={vehicle.label}
                    selected={false}
                  >
                    {vehicle.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </>
      ) : (
        <MapPageEmergency
          view={view}
          setView={setView}
          urlShare={urlShare}
          position={position}
          vehicles={vehicles}
          userLogout={userLogout}
          handleChange={handleChange}
          sharePosition={sharePosition}
          selectedVehicle={selectedVehicle}
          selectedVehicleData={selectedVehicleData}
        />
      )}
    </>
  )
}

export default MapContainerEmergency
