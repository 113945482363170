/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react'
import { shared } from '../../assets/images'

function MapGoogle({ vehicles, view, selectedVehicleData }) {
  const [count, setCount] = useState(0)
  const [googleMarkers, setGoogleMarkers] = useState(null)
  const [vehiclesLenght, setVehiclesLenght] = useState(
    Object.keys(vehicles || {}).length
  )
  const infoWindow = new google.maps.InfoWindow()
  const mapRef = useRef()

  const updateMarkers = (updatedVehicles) => {
    setCount(count + 1)
    Object.keys(updatedVehicles).forEach((key) => {
      const position = {
        lng: updatedVehicles[key].longitude,
        lat: updatedVehicles[key].latitude,
      }
      const marker = googleMarkers[key]
      if (marker) {
        googleMarkers[key].setPosition(position)
        googleMarkers[key].setIcon({
          url: updatedVehicles[key].img,
          scaledSize: new window.google.maps.Size(50, 50),
        })
        // eslint-disable-next-line no-unused-expressions
        key === selectedVehicleData[0]?.Chasis
          ? googleMarkers[key].setAnimation(window.google.maps.Animation.BOUNCE)
          : googleMarkers[key].setAnimation(null)
      }
    })
  }

  useEffect(() => {
    const markers = googleMarkers || {}
    const notEmpty = Object.keys(markers).length > 0
    if (notEmpty) {
      updateMarkers(vehicles)
    }
    setVehiclesLenght(Object.keys(vehicles || {}).length)
  }, [vehicles, selectedVehicleData])

  useEffect(() => {
    const map = new window.google.maps.Map(mapRef.current, {
      zoom: view.zoomLevel,
      center: view.center,
      mapTypeControl: false,
    })

    const markers = Object.keys(vehicles || {}).reduce(
      (reducedMarkers, vehicleKey) => {
        const vehicle = vehicles[vehicleKey]
        const markerPosition = { lng: vehicle.longitude, lat: vehicle.latitude }
        const vehicleMarked = new window.google.maps.Marker({
          position: markerPosition,
          map,
          icon: {
            url: vehicle.img,
            scaledSize: new window.google.maps.Size(50, 50),
          },
        })
        vehicleMarked.addListener('click', () => {
          infoWindow.setContent(
            "<div className='flex flex-col content-start p-2'>" +
              "<h1 className='font-semibold text-lg mb-2'>" +
              `${vehicle.Marca}` +
              '</h1>' +
              `<p>${vehicle.Modelo}</p>`
          )
          infoWindow.open({
            anchor: vehicleMarked,
            map,
            shouldFocus: true,
          })
          vehicleMarked.setMap(map)
        })

        return { ...reducedMarkers, [vehicleKey]: vehicleMarked }
      },
      {}
    )
    setGoogleMarkers(markers)
  }, [vehiclesLenght, view])

  return <div ref={mapRef} id="map" style={{ width: '100%', height: '100%' }} />
}

export { MapGoogle }
