import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { isIOS, isAndroid } from 'react-device-detect'
import {
  MapContainer,
  LoginContainer,
  VerifyCodeOtpContainer,
  SharedMapContainer,
  MapContainerEmergency,
} from './container'
import './App.css'
import { Loader, PrivateRoute } from './components'
import { AuthProvider, AuthContext } from './hooks'
import EmergencyMapContainer from './container/EmergencyMapContainer'

function App() {
  useEffect(() => {
    const pathName = window.location.hostname
    const params = window.location.href
    const newPath = params.replace(/^https?:\/\//, 'trakku://')

    // if (isIOS && pathName === 'share.trakku.app') {
    //   return window.location.replace(
    //     'https://apps.apple.com/us/app/id1611822526'
    //   )
    // }

    // if (isAndroid && pathName === 'share.trakku.app') {
    //   return window.location.replace(
    //     'https://play.google.com/store/apps/details?id=com.autecomobility.trakku&hl=es_CO'
    //   )
    // }
  }, [])

  return (
    <div className="App">
      <AuthProvider>
        <AuthContext.Consumer>
          {({ authUser }) => (
            <Routes>
              <Route path="/" element={<LoginContainer />} />
              <Route path="/SharedMap" element={<SharedMapContainer />} />
              <Route path="/qr" element={<EmergencyMapContainer />} />
              <Route element={<PrivateRoute auth={authUser.authLogin} />}>
                <Route path="/verify" element={<VerifyCodeOtpContainer />} />
              </Route>
              <Route element={<PrivateRoute auth={authUser.authToken} />}>
                <Route path="/map" element={<MapContainer />} />
                <Route path="/mapEmergency" element={<MapContainerEmergency/>} />
              </Route>
            </Routes>
          )}
        </AuthContext.Consumer>
      </AuthProvider>
      <Loader />
    </div>
  )
}

export default App
