import { styled, createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { Colors } from '../../theme';

export const ContainerStyle = styled(Container)(() => ({
  background:
    'linear-gradient(90deg, rgba(39,38,76,1) 0%, rgba(46,49,142,1) 100%)',
  overflow: 'hidden',
  position: 'relative',
  minHeight: '100vh',
  minWidth: '100vw',
}));

export const InputText = styled(TextField)(() => ({
  background: Colors.SHADOW_PURPLE,
  borderRadius: 5,
  border: 0,
  color: Colors.WHITE,
  fontSize: '1rem',
  fontWeight: 'normal',
  padding: '0.5rem',
  '&:focus': {
    background: Colors.SHADOW_PURPLE,
    color: Colors.WHITE,
  },
  '& .MuiFilledInput-root': {
    background: Colors.SHADOW_PURPLE,
    color: Colors.WHITE,
  },
}));

export const ButtonApp = styled(Button)(() => ({
  background: Colors.GREEN_BACKGROUND,
  color: Colors.BG_START,
  fontWeight: 'bold',
  overflow: 'hidden',
  position: 'relative',
  height: '40px',
  '&:hover': {
    background: '#d7f2009e',
    fontWeight: 'bold',
  },
}));
export const TitlesContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '274px',
  alignItems: 'flex-start',
}));

export const Title = styled(Typography)(() => ({
  color: Colors.WHITE,
  fontSize: '1.4rem',
  fontWeight: 'bold',
  margin: '0.2rem',
}));
export const SubTitle = styled(Typography)(() => ({
  color: Colors.WHITE,
  fontSize: '1rem',
  margin: '0.2rem',
  textAlign: 'left',
}));

export const theme = createTheme({
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          width: '100%',
          color: Colors.WHITE,
          '&:before': {
            borderBottom: `1px solid ${Colors.WHITE}`,
            transition: 'none',
          },
          '&:hover': {
            borderBottom: `2px solid ${Colors.WHITE}`,
            animation: 'none',
          },
        },
      },
    },
  },
});
