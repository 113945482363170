import React from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { MapGoogle } from '../../components';
import { AlertDialog } from '../../components/AlertDialog';
import {
  FooterContainer, 
  TextParragraph, 
  HeaderCard, 
  Title, 
} from './styles';

function SharedMap(props) {
  const { 
    view, 
    vehicle,
    position, 
    authorization,
    selectedVehicleData,
    onCloseAlert,
    openAlert,
  } = props;
  const title = 'La conexión ha caducado o la url es incorrecta';
  const subtitle = 'por favor solicita una nueva';
  return (
    <div
      style={{
        height: '100vh',
        minWidth: '100vw',
        display: 'flex',
        width: '100vw',
      }}
    >
      <HeaderCard>
        <Title>TRAKKU</Title>
      </HeaderCard>
      {authorization ? (
        <Wrapper
          apiKey="AIzaSyAINQfRs6ACIur8xjLP8pCIXrZFIoJh39o"
          version="weekly"
        >
          <MapGoogle
            view={view}
            vehicles={position}
            selectedVehicleData={selectedVehicleData}
          />     
        </Wrapper>
      )
        : (
          <AlertDialog
            selectedVehicleData
            openAlert={openAlert}
            handleCloseAlert={onCloseAlert}
            handleAceptAlert={onCloseAlert}
            handleCancelAlert={onCloseAlert}
            title={title}
            subtitle={subtitle}
            mainbuttonTitle="Cerrar"
          />
        )}
      <FooterContainer>
        <TextParragraph>
          {vehicle.Marca}
          - 
          {vehicle.Modelo}
        </TextParragraph>
      </FooterContainer>
    </div>
  );
}

export default SharedMap;
