/* eslint-disable max-len */
import React, { useState } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Backdrop from '@mui/material/Backdrop';
import { ThemeProvider } from '@mui/material/styles';
import {
  LogoutOutlined,
  PowerSettingsNew,
  ShareOutlined,
} from '@mui/icons-material';
import { Colors } from '../../theme';
import { AlertDialog } from '../AlertDialog';
import { themeSpeedDial } from '../../pages/MapPage/styles';
import { useVehiclesService } from '../../hooks';
import { loaderService } from '../../services';


const actions = [
  { icon: <PowerSettingsNew />, name: 'Encender/Apagar Vehículo' },
];

const alertDialogTitles = [
  {
    title: 'Apagado remoto',
    subtitle: '¿Desea apagar este vehiculo de forma remota?',
  },
  {
    title: 'Encendido remoto',
    subtitle: '¿Desea encender este vehiculo de forma remota?',
  },
];
const mainbuttonTitle = 'Aceptar';
const secondarybuttonTitle = 'Cancelar';
const titleUrl = '¡Copia la URL y compartela!';
const copiedTitle = '¡Copiado, Ahora compartela!';

function MenuMap({
  selectedVehicleData,
  userLogout,
  sharePosition,
  urlShare,
}) {
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openUrl, setOpenUrl] = useState(false);
  const [copied, setCopied] = useState(false);
  const [relayState, setRelayState] = useState(null);

  const { 
    checkStatusRelay, writeMessageWS, turnOffVehicle, turnONVehicle, 
  } = useVehiclesService();

  const handleTurnOffOn = async () => {
    if (relayState === 0) {
      await turnOffVehicle(selectedVehicleData[0].vehiculo.Chasis);
    } else if (relayState === 1) {
      await turnONVehicle(selectedVehicleData[0].vehiculo.Chasis);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenAlert = async () => {
    let sum = 0;
    setRelayState(null);
    const { success } = await writeMessageWS(selectedVehicleData[0].Chasis, 'get', 4);
    if (success === 1) {
      loaderService.show();
      const interval = setInterval(async () => {
        sum += 1;
        const { relay } = await checkStatusRelay(selectedVehicleData[0].Chasis);
        if (relay !== null) {
          clearInterval(interval);
          loaderService.hide();
          setRelayState(relay);
          setOpenAlert(true);
        }

        if (sum === 10) {
          loaderService.hide();
          clearInterval(interval);
        }
      }, 2000);
    }
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
    setOpenUrl(false);
    setCopied(false);
  };
  const handleAceptAlert = () => {
    handleCloseAlert();
    handleTurnOffOn();
  };
  const handleOpenUrl = () => {
    setOpenUrl(true);
    sharePosition();
  };

  const handleCopytext = () => {
    navigator.clipboard.writeText(urlShare);
    setCopied(true);
  };

  const validation = selectedVehicleData !== null;

  const selectTitle = () => {
    let title;
    let subtitle;
    if (validation) {
      title = relayState === 0
        ? alertDialogTitles[0].title
        : alertDialogTitles[1].title;
      subtitle = relayState === 0
        ? alertDialogTitles[0].subtitle
        : alertDialogTitles[1].subtitle;
      return [title, subtitle];
    }
  };
  const selected = selectTitle();

  return (
    <>
      <AlertDialog
        mainbuttonTitle="Copiar Url"
        secondarybuttonTitle="Cerrar"
        selectedVehicleData={selectedVehicleData}
        handleAceptAlert={handleCopytext}
        handleCloseAlert={handleCloseAlert}
        handleCancelAlert={handleCloseAlert}
        openAlert={openUrl}
        title={copied ? copiedTitle : titleUrl}
        subtitle={urlShare}
      />
      <AlertDialog
        mainbuttonTitle={mainbuttonTitle}
        secondarybuttonTitle={secondarybuttonTitle}
        selectedVehicleData={selectedVehicleData}
        handleAceptAlert={handleAceptAlert}
        handleCloseAlert={handleCloseAlert}
        handleCancelAlert={handleCloseAlert}
        openAlert={openAlert}
        title={selected ? selected[0] : null}
        subtitle={selected ? selected[1] : null}
      />
      <ThemeProvider theme={themeSpeedDial}>
        <Backdrop open={open} sx={{ color: Colors.BACKGROUND_CIAN }} />
        <SpeedDial
          ariaLabel="menu"
          sx={{ position: 'absolute', bottom: 100, right: 16 }}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {actions.map((action) => (
            <SpeedDialAction
              color="secondary"
              key={action.name}
              icon={action.icon}
              tooltipOpen
              onClick={handleOpenAlert}
              tooltipTitle={action.name}
            />
          ))}
          <SpeedDialAction
            color="secondary"
            key="Compartir Ubicación"
            icon={<ShareOutlined />}
            tooltipOpen
            tooltipTitle="Compartir Ubicación"
            onClick={handleOpenUrl}
          />
          <SpeedDialAction
            color="secondary"
            key="Cerrar Sesión"
            icon={<LogoutOutlined />}
            tooltipOpen
            tooltipTitle="Cerrar Sesión"
            onClick={userLogout}
          />
        </SpeedDial>
      </ThemeProvider>
    </>
  );
}

export { MenuMap };
